import * as React from "react"

const Statistics = () => {

    const Statistics = [
        {
            count: '15k+',
            title: 'Completed work',
            subtitle: 'Recently successfully implemented'
        },
        {
            count: '5k+',
            title: 'Clients',
            subtitle: 'Regular active clients'
        },
        {
            count: '3k+',
            title: 'Professionals',
            subtitle: 'Currently working on the platform'
        }

    ]

    return (
        <div className="container w-full flex flex-wrap md:flex-nowrap justify-between my-0 mx-auto rounded p-4 container gap-6 relative md:top-[-90px]">
            {Statistics.map((item, index) => {
                return(
                    <div className="shadow p-6 w-full lg:w-[32%] bg-white rounded-md">
                        <div className="flex items-center">
                            <div className="">
                                <h3 className="text-3xl font-bold">{item.count}</h3>
                                <p className="text-xl font-[400] tracking-wide my-1">
                                    {item.title}
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <p className="font-[400] text-lg">
                                {item.subtitle}
                            </p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Statistics

