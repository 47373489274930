import * as React from "react"

import appleTouchIcon from '../../images/icons/apple-touch-icon.png'
import favicon32 from '../../images/icons/favicon-32x32.png'
import favicon16 from '../../images/icons/favicon-16x16.png'
import favicon from '../../images/icons/favicon.ico'

export default function SEO({title, description, children}) {
    return (
      <>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="icon" type="image/x-icon" href={favicon} />

        <title>{title}</title>
        <meta name="description" content={description} />

        <script type='application/ld+json'>
          {`{
                        '@context': 'https://schema.org',
                        '@type': 'WebSite',
                        '@id': 'https://writer.freelancewriting.center',
                        'headline': '${title}',
                        'description': '${description}'
                    }`}
        </script>
        {children}
      </>
    )
}