import React from "react";
function Hero(props) {
	return (
        <section className="flex justify-center bg-[url('../images/hero-bg.jpg')] bg-cover md:min-h-[670px] md:pb-0 pb-20 pt-[122px] text-white">
            <div className="container">
                <h1 className="md:text-6xl text-4xl mt-12 font-[700]">
                    Join the team of professionals
                </h1>
                <p className="text-xl leading-relaxed mt-5 max-w-[620px]">
                    Our meticulous content creation process will make your business sell better through effective and laser-sharp marketing copy.
                    Our team of copywriters and content strategists are here to help you achieve your goals.
                </p>
                <a href="https://writer.freelancewriting.center/register" className='flex justify-center items-center w-40 mt-10 rounded py-2 px-8 border border-white bg-white text-black'>
                    Try it out
                </a>
            </div>
        </section>
	);
}

export default Hero;
