import React from 'react';

//images
import Writing from '../images/writing.jpg'
import Office from '../images/office.jpg'
import Team from '../images/team.jpg'
import TeamHands from '../images/team-hands.jpeg'
import Old from '../images/old.jpg'

function HowItWorks() {
    const list = [
        {
            title: 'Register on our platform',
            text: 'Our user-friendly system promises a quick, easy, and absolutely stress-free sign-up process.',
            img: Writing
        },
        {
            title: 'Let Us Know What You Want',
            text: `Give us a clear idea of your vision, goals and needs and our team will deliver offers that meet your expectations.`,
            img: Office
        },
        {
            title: 'Choose Your Offer',
            text: 'Read our offers creators’ profiles and reviews. Then pick the that best fits your task’s requirements.',
            img: Team
        },
        {
            title: 'Track the Task’s Progress',
            text: 'We provide real-time communication with clients. Stay up to date by simply logging into our website.',
            img: TeamHands
        },
        {
            title: 'Get rewarded',
            text: `in the easiest way after completing the work, collect your reward.`,
            img: Old
        }
    ]
    return (
        <div className='flex justify-center font-thin'>
            <div className='container flex flex-col'>
                <h2 className='text-primary md:text-6xl text-4xl md:mb-10 mb-5 font-[600]'>How the platform works</h2>
                <p className='max-w-p[570px] text-xl text-[rgba(47,47,47,.6)] md:mb-20 mb-10'>
                    In the post-covid world, it is vital to be able to work remotely. However, we still need to maintain the integrity of our family-like organisation. 
                    Our family of copywriters work remotely, however, they remain part of our family and work as one unit with the help of a tapestry of innovative technical solutions. 
                    For you the end-client, this freelance-based model means that you can find the most suitable professionals for all your content needs 24/7 or whenever you call for it.
                </p>
                {list.map((item, index) => {
                    return(
                        <div key={index} className='hww-wrapper flex md:flex-row flex-col items-center md:flex-row-reverse md:mb-12 mb-5 pb-12'>
                            <div className='hww-info flex flex-col md:mb-0 mb-10'>
                                <div className='mb-2 text-xl'>Step: {index + 1}</div>
                                <div className='md:max-w-[380px] max-w-full text-3xl mb-5 font-[700]'>{item.title}</div>
                                <div className='w-full text-xl md:leading-relaxed text-[rgba(47,47,47,.6)] font-thin'>{item.text}</div>
                            </div>
                            <div className='hww-img relative md:min-w-[470px] md:h-[315px]'>
                                <img className='w-full h-full rounded-br-[80px] relative z-20' src={item.img} alt={item.title} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default HowItWorks;