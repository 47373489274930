import React from 'react';

function Services(props) {
    const list = [
        {
            title: 'Website Text',
            text: `The text we provide for your website’s pages like Home Page, FAQs, and About is exquisitely written to ensure keyword optimization and high conversion.
            We write strategically crafted copy to highlight your product or service and make your website sell.`
        },
        {
            title: 'Blog and News Articles',
            text: 'We produce accurate, engaging, and well-researched articles. Our talent for writing short-form content guarantees that information is delivered quickly and efficiently.'
        },
        {
            title: 'Social Media Content',
            text: `If you need to boost your social media presence, we are experienced in providing clients the text needed to gain content views. We specialize in formulating marketable text, whether it's naming, slogans, or just good copy, for banner ads or posts that will appear on Facebook, Instagram, Twitter, and other popular social media websites.`
        },
        {
            title: 'Technical documentation',
            text: `Our excellent copywriters can create technical documentation, manuals, guides for appliances, equipment, electronics, etc.`
        },
        {
            title: 'Proofreading / Updating legacy content',
            text: `Information changes over time and outdated content can easily fall through the cracks of search engines. Want to keep your original content but also improve it at the same time? Our team can accomplish that. We can enhance your existing content by employing best practices in search engine optimization.`
        },
        {
            title: 'Email Campaigns',
            text: 'We can help you keep your clientele informed, interested, and engaged. Our expertise in leveraging the power of well-designed email campaigns guarantees that you will retain and grow your mailing list.'
        },
        {
            title: 'Print Ads and Digital Infographics',
            text: 'Our team crafts informational content that embodies the tone, design, and aesthetic your business requires. Our products pack a lot of style and substance whether in print or digital format.'
        },
        {
            title: 'Translation Services',
            text: 'Penetrate new markets by talking to your customers in their own language. Our professional team can provide you with clear, accurate, and effective translations of your content.'
        }
        
    ]
    return (
        <div className='flex justify-center pt-20'>
            <div className='container'>
                <h2 className='text-primary md:text-6xl text-4xl md:mb-20 mb-10 font-[600]'>
                    Services for our clients
                </h2>
                {list.map((item, index) => {
                    return(
                        <div
                            key={index}
                            className='flex md:flex-row flex-col justify-between items-start md:mb-12 md:pb-12 mb-5 pb-5 border-b border-b-grey last:border-none'
                        >
                            <div className='max-w-[280px] md:text-3xl text-xl md:pb-0 pb-5 font-[700]'>{item.title}</div>
                            <div className='md:w-4/6 md:max-w-p[570px] w-full max-w-full text-xl md:leading-relaxed text-[rgba(47,47,47,0.6)] font-thin'>{item.text}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Services;