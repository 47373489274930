import * as React from "react";

import Layout from '../layouts/layout'


import SEO from "../components/SEO/SEO"
//components
import Hero from '../components/Hero'
import Statistics from "../components/Statistics"
import Services from '../components/Services'
import HowItWorks from '../components/HowItWorks'
import Reviews from '../components/Reviews'
import WhyWeAreBest from '../components/WhyWeAreBest'
import ContactUs from '../components/ContactUs'

const IndexPage = () => {
	return (
        <div>
            <Layout>
                <Hero />
                <Statistics/>
                <HowItWorks />
                <Services />
                <Reviews />
                <WhyWeAreBest />
                <ContactUs />
            </Layout>
        </div>
    )
};

export default IndexPage;

export const Head = () => {
    return (
        <SEO
            title='FreelanceWriting.center'
            description='A short description goes here.'
        />
    )
}