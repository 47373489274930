import React from 'react';

function WhyWeAreBest() {
    const list = [
        {
            title: 'Usability',
            text: 'Ease of use of the platform'
        },
        {
            title: 'Support',
            text: 'Support service that is always there'
        },
        {
            title: 'Wide client base',
            text: 'The platform has a wide customer base'
        },
        {
            title: 'Remuneration',
            text: 'Generous reward after the work done'
        }
    ]
    return (
        <div className='flex justify-center my-20'>
            <div className='container'>
                <h2 className='text-primary md:text-6xl text-4xl mb-10 font-[600]'>Why we ?</h2>
                <div className='flex flex-wrap'>
                    {list.map((item, index) => {
                        return(
                            <div key={index} className="md:w-[350px] w-full p-7 bg-[rgba(30,144,255,0.2)] rounded-br-[50px] mr-5 mb-10">
                                <h3 className='text-2xl mb-3 font-[600]'>{item.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: item.text}} className="mb-3"/>
                                <span className='block w-10 h-px bg-slate-500'/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default WhyWeAreBest;